import React, { useState } from "react"
import Layout from "../components/Layout"
import ExitLink from "../components/ExitLink/ExitLink"
import GlobalWrapper from "../components/GlobalWrapper/GlobalWrapper"
import DownloadButton from "../components/DownloadButton/DownloadButton"
import Accordion from "../components/Accordion/Accordion"
import Title from "../components/Title/Title"
import Footnotes from "../components/Footnotes/Footnotes"

import * as styles from "../styles/pages/pi.module.css"

import PiTable1 from "../images/pi/Table 1.jpg"
import PiTable2 from "../images/pi/Table 2.png"
import PiTable3 from "../images/pi/Table 3.png"
import PiTable4 from "../images/pi/Table 4.png"
import PiTable5 from "../images/pi/Table 5.png"
import PiTable6 from "../images/pi/Table 6.png"
import PiTable7 from "../images/pi/Table 7.png"
import PiTable8 from "../images/pi/Table 8.png"
import PiFigure1 from "../images/pi/Figure 1.png"
import InternalHash from "../components/InternalHash/InternalHash"

import { GtagHead } from "../components/GTAG"
export function Head() {
  return (
    <>
      <GtagHead />
      <title>Full Prescribing Information - LOQTORZI® (toripalimab-tpzi)</title>
      <meta
        name="description"
        content="Interactive LOQTORZI® Prescribing Information"
      />
    </>
  )
}

export default function PI() {
  const [accordiongOpen, setAccordionOpen] = useState("")

  const handleInternalHashClick = id => {
    setAccordionOpen(id)
    setTimeout(() => {
      setAccordionOpen("")
    }, 100)
  }

  return (
    <Layout>
      <Title pageName={"Interactive PI"}>
        Interactive <br className="tablet:hidden" />
        prescribing <br />
        Information
      </Title>
      <GlobalWrapper className="py-[2.5rem]" showBorderLeft={true}>
        <DownloadButton
          file={"/pdf/prescribing-information.pdf"}
          className="downloadPrescribingInformationPdf m-auto"
          title="Download the full prescribing information for healthcare professionals"
        >
          <span>Download PDF version</span>
        </DownloadButton>
      </GlobalWrapper>

      <GlobalWrapper className="pt-[2.5rem] text-left desktop:text-center font-BarlowCondensedBold" showBorderLeft={true}>
        <div className={styles.AccordionBgTitle}>
          <p className={styles.titleAccordion}>HIGHLIGHTS OF PRESCRIBING INFORMATION</p>
        </div>
      </GlobalWrapper>

      <Accordion
        GlobalWrapperClassname={"pt-[1rem]"}
        idOpen={accordiongOpen}
        items={[
          {
            title: "HIGHLIGHTS OF PRESCRIBING INFORMATION",
            content: (
              <div>
                <p className="font-MontserratSemiBold text-[1.6rem]">
                  These highlights do not include all the information needed to
                  use LOQTORZI safely and effectively. See full prescribing
                  information for LOQTORZI.
                </p>
                <p className="font-MontserratSemiBold text-[1.6rem]">
                  LOQTORZI (toripalimab-tpzi) injection, for intravenous use{" "}
                  <br />
                  Initial U.S. Approval: 2023
                </p>

                <section className="mb-[2.5rem]">
                  <div className={styles.titleLine}>
                    <div className="mb-0 font-MontserratSemiBold text-[1.6rem]">
                      RECENT AND MAJOR CHANGES
                    </div>
                  </div>
                  <div class="flex justify-between">
                    <p className="text-[1.6rem]">
                      Warnings and Precautions (5.1)
                    </p>
                    <p className="text-[1.6rem]">04/2024</p>
                  </div>
                </section>

                <section className="mb-[2.5rem]">
                  <div className={styles.titleLine}>
                    <div className="mb-0 font-MontserratSemiBold text-[1.6rem]">
                      INDICATIONS AND USAGE
                    </div>
                  </div>
                  <p className="text-[1.6rem]">
                    LOQTORZI is a programmed death receptor-1 (PD-1)- blocking
                    antibody indicated:
                  </p>
                  <ul className="bullet-list">
                    <li>
                      in combination with cisplatin and gemcitabine, for
                      first-line treatment of adults with metastatic or with
                      recurrent locally advanced nasopharyngeal carcinoma (NPC).{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("1")
                        }}
                        hash="1.1"
                        text="(1.1)"
                      />
                    </li>
                    <li>
                      as a single agent for the treatment of adults with
                      recurrent unresectable or metastatic NPC with disease
                      progression on or after a platinum-containing chemotherapy{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("1")
                        }}
                        hash="1.2"
                        text="(1.2)"
                      />
                    </li>
                  </ul>
                </section>

                <section className="mb-[2.5rem]">
                  <div className={styles.titleLine}>
                    <div className="mb-0 font-MontserratSemiBold text-[1.6rem]">
                      DOSAGE AND ADMINISTRATION
                    </div>
                  </div>
                  <div>In combination with cisplatin and gemcitabine:</div>
                  <ul className="bullet-list">
                    <li>
                      240 mg intravenously every three weeks{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        hash="2.1"
                        text="(2.1)"
                      />
                    </li>
                  </ul>
                  <div>As a single agent:</div>
                  <ul className="bullet-list">
                    <li>
                      3 mg/kg intravenously every two weeks{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        hash="2.1"
                        text="(2.1)"
                      />
                    </li>
                  </ul>
                  <p className="mt-[1em]">
                    <span className="underline">First Infusion:</span> Infuse
                    over 60 minutes.
                    <br />
                    <span className="underline">Subsequent Infusions:</span> If
                    no infusion-related reactions occurred during the first
                    infusion, subsequent infusions may be administered over 30
                    minutes.
                  </p>
                </section>

                <section className="mb-[2.5rem]">
                  <div className={styles.titleLine}>
                    <p className="mb-0 font-MontserratSemiBold text-[1.6rem]">
                      DOSAGE FORMS AND STRENGTHS
                    </p>
                  </div>
                  <p>
                    Injection: 240 mg/6 mL (40 mg/mL) solution in a single-dose
                    vial{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("3")
                      }}
                      hash="3"
                      text="(3)"
                    />
                  </p>
                </section>

                <section className="mb-[2.5rem]">
                  <div className={styles.titleLine}>
                    <p className="mb-0 font-MontserratSemiBold">
                      CONTRAINDICATIONS
                    </p>
                  </div>
                  <p className="font-MontserratRegular">None</p>
                </section>

                <section className="mb-[2.5rem]">
                  <div className={styles.titleLine}>
                    <p className="font-MontserratSemiBold">
                      WARNINGS AND PRECAUTIONS
                    </p>
                  </div>
                  <ul className="bullet-list">
                    <li>
                      <span className="underline">
                        Immune-Mediated Adverse Reactions
                      </span>{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("5")
                        }}
                        hash="5.1"
                        text="(5.1)"
                      />
                      <ul className="bullet-list-circle">
                        <li>
                          Immune-mediated adverse reactions, which may be severe
                          or fatal, can occur in any organ system or tissue,
                          including the following: immune-mediated pneumonitis,
                          immune-mediated colitis, immune-mediated hepatitis,
                          immune-mediated endocrinopathies, immune-mediated
                          nephritis with renal dysfunction, immune-mediated
                          dermatologic adverse reactions, and solid organ
                          transplant rejection.
                        </li>
                        <li>
                          Monitor for early identification and management.
                          Evaluate liver enzymes, creatinine, and thyroid
                          function at baseline and periodically during
                          treatment.{" "}
                          <InternalHash
                            onClick={() => {
                              handleInternalHashClick("5")
                            }}
                            hash="5.1"
                            text="(5.1)"
                          />
                        </li>
                        <li>
                          Withhold or permanently discontinue based on severity
                          and type of reaction.{" "}
                          <InternalHash
                            onClick={() => {
                              handleInternalHashClick("5")
                            }}
                            hash="5.1"
                            text="(5.1)"
                          />
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span className="underline">
                        Infusion-related reactions:
                      </span>{" "}
                      Interrupt, slow the rate of infusion, or permanently
                      discontinue LOQTORZI based on the severity of reaction.{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("5")
                        }}
                        hash="5.2"
                        text="(5.2)"
                      />
                    </li>
                    <li>
                      <span className="underline">
                        Complications of allogeneic HSCT:
                      </span>{" "}
                      Fatal and other serious complications can occur in
                      patients who receive allogeneic HSCT before or after being
                      treated with a PD-1/PD-L1 blocking antibody.{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("5")
                        }}
                        hash="5.3"
                        text="(5.3)"
                      />
                    </li>
                    <li>
                      <span className="underline">Embryo-fetal toxicity:</span>{" "}
                      Can cause fetal harm. Advise females of reproductive
                      potential of the potential risk to a fetus and to use
                      effective method of contraception.{" "}
                      <span className="text-blue-200">
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("5")
                          }}
                          hash="5.4"
                          text="(5.4"
                        />
                        ,{" "}
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("8")
                          }}
                          hash="8.1"
                        />
                        ,{" "}
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("8")
                          }}
                          hash="8.3"
                          text="8.3)"
                        />
                      </span>
                    </li>
                  </ul>
                </section>

                <section className="mb-[2.5rem]">
                  <div className={styles.titleLine}>
                    <p className="font-MontserratSemiBold text-[1.6rem]">
                      ADVERSE REACTIONS
                    </p>
                  </div>
                  <ul className="bullet-list">
                    <li>
                      <span className="underline">
                        LOQTORZI in Combination with Cisplatin and Gemcitabine
                      </span>
                      <br />
                      The most common adverse reactions (≥ 20%) are nausea,
                      vomiting, decreased appetite, constipation,
                      hypothyroidism, rash, pyrexia, diarrhea, peripheral
                      neuropathy, cough, musculoskeletal pain, upper respiratory
                      infection, insomnia, dizziness, and malaise.{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("6")
                        }}
                        hash="6.1"
                        text="(6.1)"
                      />
                    </li>
                    <li>
                      <span className="underline">
                        LOQTORZI as a Single Agent
                      </span>
                      <br />
                      The most common adverse reactions (≥ 20%) are fatigue,
                      hypothyroidism and musculoskeletal pain.{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("6")
                        }}
                        hash="6.1"
                        text="(6.1)"
                      />
                    </li>
                  </ul>
                </section>

                <section className="mb-[2.5rem]">
                  <p className="font-MontserratSemiBold text-[1.6rem]">
                    To report SUSPECTED ADVERSE REACTIONS, contact Coherus
                    BioSciences, Inc. at 1-800-483-3692 or FDA at 1-800-FDA-1088
                    or{" "}
                    <ExitLink
                      className="text-blue-200 no-underline"
                      url="https://www.fda.gov/medwatch"
                    >
                      www.fda.gov/medwatch.
                    </ExitLink>
                  </p>
                </section>

                <section className="mb-[2.5rem]">
                  <div className={styles.titleLine}>
                    <p className="mb-0 font-MontserratSemiBold">
                      USE IN SPECIFIC POPULATIONS
                    </p>
                  </div>
                  <p>
                    <u>Lactation</u>: Advise not to breastfeed.
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("8")
                      }}
                      hash="8.2"
                      text="(8.2)"
                    />
                  </p>
                </section>

                <section className="mb-[2.5rem]">
                  <p className="font-MontserratSemiBold">
                    See{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("17")
                      }}
                      hash="17"
                    />{" "}
                    for PATIENT COUNSELING INFORMATION and FDA-approved
                    Medication Guide.
                  </p>
                </section>

                <p className="font-MontserratSemiBold text-right">
                  Revised: 04/2024
                </p>
              </div>
            ),
          },
        ]}
      />
      <GlobalWrapper className="pt-[2.5rem] text-left desktop:text-center font-BarlowCondensedBold" showBorderLeft={true}>
        <div className={styles.AccordionBgTitle}>
          <p className={styles.titleAccordion}>FULL PRESCRIBING INFORMATION: CONTENTS</p>
        </div>
      </GlobalWrapper>
      <Accordion
        GlobalWrapperClassname={"pt-[1rem]"}
        idOpen={accordiongOpen}
        items={[
          {
            title: "FULL PRESCRIBING INFORMATION: CONTENTS*",
            content: (
              <div>
                <p className="font-MontserratSemiBold">
                  WARNING: TITLE OF WARNING
                </p>
                <div className="large:flex">
                  <div className="large:max-w-[55rem] large:mr-[5rem]">
                    <ul className={styles.contentsList}>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("1")
                          }}
                          hash="1"
                          text="1 INDICATIONS AND USAGE"
                        />
                        <ul>
                          <li index="1.1">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("1")
                              }}
                              hash="1.1"
                              text="First-line Treatment of Metastatic or Recurrent, Locally Advanced NPC with Cisplatin and Gemcitabine"
                            />
                          </li>
                          <li index="1.2">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("1")
                              }}
                              hash="1.2"
                              text="Previously Treated Unresectable or Metastatic NPC"
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("2")
                          }}
                          hash="2"
                          text="2 DOSAGE AND ADMINISTRATION"
                        />
                        <ul>
                          <li index="2.1">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("2")
                              }}
                              hash="2.1"
                              text="Recommended Dosage"
                            />
                          </li>
                          <li index="2.2">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("2")
                              }}
                              hash="2.2"
                              text="Dosage Modifications"
                            />
                          </li>
                          <li index="2.3">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("2")
                              }}
                              hash="2.3"
                              text="Preparation and Administration"
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("3")
                          }}
                          hash="3"
                          text="3 DOSAGE FORMS AND STRENGTHS"
                        />
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("4")
                          }}
                          hash="4"
                          text="4 CONTRAINDICATIONS"
                        />
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("5")
                          }}
                          hash="5"
                          text="5 WARNINGS AND PRECAUTIONS"
                        />
                        <ul>
                          <li index="5.1">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("5")
                              }}
                              hash="5.1"
                              text="Severe and Fatal Immune-Mediated Adverse Reactions"
                            />
                          </li>
                          <li index="5.2">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("5")
                              }}
                              hash="5.2"
                              text="Infusion-Related Reactions"
                            />
                          </li>
                          <li index="5.3">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("5")
                              }}
                              hash="5.3"
                              text="Complications of Allogeneic HSCT"
                            />
                          </li>
                          <li index="5.4">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("5")
                              }}
                              hash="5.4"
                              text="Embryo-Fetal Toxicity"
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("6")
                          }}
                          hash="6"
                          text="6 ADVERSE REACTIONS"
                        />
                        <ul>
                          <li index="6.1">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("6")
                              }}
                              hash="6.1"
                              text="Clinical Trials Experience"
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("8")
                          }}
                          hash="8"
                          text="8 USE IN SPECIFIC POPULATIONS"
                        />
                        <ul>
                          <li index="8.1">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("8")
                              }}
                              hash="8.1"
                              text="Pregnancy"
                            />
                          </li>
                          <li index="8.2">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("8")
                              }}
                              hash="8.2"
                              text="Lactation"
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="large:max-w-[43rem]">
                    <ul className={styles.contentsList}>
                      <li>
                        <ul>
                          <li index="8.3">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("8")
                              }}
                              hash="8.3"
                              text="Females and Males of Reproductive Potential"
                            />
                          </li>
                          <li index="8.4">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("8")
                              }}
                              hash="8.4"
                              text="Pediatric Use"
                            />
                          </li>
                          <li index="8.5">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("8")
                              }}
                              hash="8.5"
                              text="Geriatric Use"
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("11")
                          }}
                          hash="11"
                          text="11 DESCRIPTION"
                        />
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("12")
                          }}
                          hash="12"
                          text="12 CLINICAL PHARMACOLOGY"
                        />
                        <ul>
                          <li index="12.1">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("12.1")
                              }}
                              hash="12.1"
                              text="Mechanism of Action"
                            />
                          </li>
                          <li index="12.2">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("12.2")
                              }}
                              hash="12.2"
                              text="Pharmacodynamics"
                            />
                          </li>
                          <li index="12.3">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("12.3")
                              }}
                              hash="12.3"
                              text="Pharmacokinetics"
                            />
                          </li>
                          <li index="12.6">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("12.6")
                              }}
                              hash="12.6"
                              text="Immunogenicity"
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("13")
                          }}
                          hash="13"
                          text="13 NONCLINICAL TOXICOLOGY"
                        />
                        <ul>
                          <li index="13.1">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("13")
                              }}
                              hash="13.1"
                              text="Carcinogenesis, Mutagenesis, Impairment of Fertility"
                            />
                          </li>
                          <li index="13.2">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("13")
                              }}
                              hash="13.2"
                              text="Animal Toxicology and/or Pharmacology"
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("14")
                          }}
                          hash="14"
                          text="14 CLINICAL STUDIES"
                        />
                        <ul>
                          <li index="14.1">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("14")
                              }}
                              hash="14.1"
                              text="First-line Treatment of Metastatic or Recurrent, Locally Advanced NPC with Cisplatin and Gemcitabine"
                            />
                          </li>
                          <li index="14.2">
                            <InternalHash
                              onClick={() => {
                                handleInternalHashClick("14")
                              }}
                              hash="14.2"
                              text="Previously Treated Unresectable or Metastatic NPC"
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("16")
                          }}
                          hash="16"
                          text="16 HOW SUPPLIED/STORAGE AND HANDLING"
                        />
                      </li>
                      <li>
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("17")
                          }}
                          hash="17"
                          text="17 PATIENT COUNSELING INFORMATION"
                        />
                      </li>
                    </ul>
                    <Footnotes
                      className="mt-[1rem] !text-[1.2rem]"
                      items={[
                        {
                          bullet: "*",
                          text: (
                            <span>
                              Sections or subsections omitted from the full
                              prescribing information are not listed.
                            </span>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
      <GlobalWrapper className="pt-[2.5rem] text-left desktop:text-center font-BarlowCondensedBold" showBorderLeft={true}>
        <div className={styles.AccordionBgTitle}>
          <p className={styles.titleAccordion}>FULL PRESCRIBING INFORMATION</p>
        </div>
      </GlobalWrapper>

      <Accordion
        GlobalWrapperClassname={"pt-[1rem]"}
        idOpen={accordiongOpen}
        items={[
          {
            id: "1",
            title: <span id="1">1 INDICATIONS AND USAGE</span>,
            content: (
              <div>
                <div className="">
                  <div id="1.1">
                    <div className={styles.subHeader}>
                      1.1 First-line Treatment of Metastatic or Recurrent,
                      Locally Advanced NPC with Cisplatin and Gemcitabine
                    </div>
                    <div>
                      LOQTORZI is indicated, in combination with cisplatin and
                      gemcitabine, for the first-line treatment of adults with
                      metastatic or with recurrent, locally advanced
                      nasopharyngeal carcinoma (NPC).
                    </div>
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <div id="1.2">
                    <div className={styles.subHeader}>
                      1.2 Previously Treated Unresectable or Metastatic NPC
                    </div>
                    <div>
                      LOQTORZI is indicated, as a single agent, for the
                      treatment of adults with recurrent unresectable or
                      metastatic NPC with disease progression on or after a
                      platinum-containing chemotherapy.
                    </div>
                  </div>
                </div>
              </div>
            ),
          },
          {
            id: "2",
            title: <span id="2">2 DOSAGE AND ADMINISTRATION</span>,
            content: (
              <div>
                <div className="mb-[1rem]">
                  <div id="2.1">
                    <div className={styles.subHeader}>
                      2.1 Recommended Dosage
                    </div>
                    <p>
                      The recommended dosages of LOQTORZI are provided in{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        hash="table-1"
                        text="Table 1"
                      />
                      . Administer as recommended{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        className={"italic"}
                        hash="2.3"
                        text="[see Dosage and Administration (2.3)]"
                      />
                      .
                    </p>
                    <p className="font-MontserratSemiBold" id="table-1">
                      Table 1: Recommended Dosage
                    </p>
                    <img
                      className="w-full h-auto"
                      src={PiTable1}
                      alt="Table 1"
                    />
                  </div>
                </div>
                <div className="mb-[1rem]">
                  <div id="2.2">
                    <p className={styles.subHeader}>2.2 Dosage Modifications</p>
                    <p>
                      No dose reductions of LOQTORZI are recommended. In
                      general, withhold LOQTORZI for severe (Grade 3)
                      immune-mediated adverse reactions. Permanently discontinue
                      LOQTORZI for life-threatening (Grade 4) immune-mediated
                      adverse reactions, recurrent severe (Grade 3)
                      immune-mediated reactions that require systemic
                      immunosuppressive treatment, or an inability to reduce
                      prednisone to 10 mg per day or less (or equivalent) within
                      12 weeks of initiating steroids.
                    </p>
                    <p>
                      Dosage modifications for LOQTORZI for adverse reactions
                      that require management different from these general
                      guidelines are summarized in{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        hash="table-2"
                        text="Table 2"
                      />
                      .
                    </p>
                    <p id="table-2" className="font-MontserratSemiBold">
                      Table 2: Recommended Dosage Modifications for Adverse
                      Reactions{" "}
                    </p>
                    <img
                      className="w-full h-auto mb-[1rem]"
                      src={PiTable2}
                      alt="Table 2"
                    />

                    <Footnotes
                      className={"!text-[1.2rem]"}
                      items={[
                        {
                          bullet: <sup>1</sup>,
                          text: (
                            <span>
                              Based on National Cancer Institute (NCI) Common
                              Terminology for Adverse Events (CTCAE) version 5.0
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>2</sup>,
                          text: (
                            <span>
                              Resume LOQTORZI in patients with complete or
                              partial resolution to Grade 0-1 after
                              corticosteroid taper. Permanently discontinue if
                              no complete or partial resolution within 12 weeks
                              of initiating steroids or inability to reduce
                              prednisone to 10 mg per day or less (or
                              equivalent) within 12 weeks of initiating
                              steroids.
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>3</sup>,
                          text: (
                            <span>
                              If AST and ALT are less than or equal to ULN at
                              baseline in patients with liver involvement,
                              withhold or permanently discontinue LOQTORZI based
                              on recommendations for hepatitis with no liver
                              involvement.
                            </span>
                          ),
                        },
                        {
                          bullet: "",
                          text: (
                            <span>
                              ALT=alanine aminotransferase, AST=aspartate
                              aminotransferase, DRESS=drug rash with
                              eosinophilia and systemic symptoms, SJS=Stevens
                              Johnson syndrome, TEN=toxic epidermal necrolysis,
                              ULN=upper limit of normal
                            </span>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="mb-[1rem]">
                  <div id="2.3">
                    <p className="font-MontserratSemiBold">
                      2.3 Preparation and Administration
                    </p>
                    <p className="underline">
                      Preparation for Intravenous Infusion
                    </p>
                    <ul className="bullet-list">
                      <li>
                        Visually inspect the solution for particulate matter and
                        discoloration. The solution is clear to slightly
                        opalescent, colorless to slightly yellow. Discard the
                        vial if visible particles are observed.
                      </li>
                      <li>
                        Withdraw the required volume of LOQTORZI and inject
                        slowly into a 100 mL or 250 mL infusion bag containing
                        0.9% Sodium Chloride Injection, USP.{" "}
                        <strong>
                          Mix diluted solution by gentle inversion.
                        </strong>{" "}
                        Do not shake. The final concentration of the diluted
                        solution should be between 1 mg/mL to 3 mg/mL.
                      </li>
                      <li>
                        LOQTORZI is compatible with polypropylene infusion bags
                        and infusion sets with 0.2 or 0.22 micron in-line
                        filter.
                      </li>
                      <li>Discard any unused portion left in the vial.</li>
                    </ul>
                    <p className="mt-[1em] underline">
                      Storage of Diluted Solution for Infusion{" "}
                    </p>
                    <p>LOQTORZI does not contain a preservative.</p>
                    <p>
                      If the diluted solution is not administered immediately,
                      store either:
                    </p>
                    <ul className="bullet-list">
                      <li>
                        At room temperature, 20°C to 25°C (68°F to 77°F), for no
                        more than 8 hours from the time of dilution to the
                        completion of the infusion. Discard diluted solution
                        stored at room temperature after 8 hours.
                        <p className="my-[1rem]">Or</p>
                      </li>
                      <li>
                        Refrigerated at 2°C to 8°C (36°F to 46°F) for no more
                        than 24 hours from the time of dilution to the
                        completion of the infusion. If refrigerated, allow the
                        diluted solution to come to room temperature prior to
                        administration. Discard the refrigerated diluted
                        solution after 24 hours.
                      </li>
                    </ul>
                    <p className="mt-[1em]">Do not freeze.</p>
                    <p className="underline">Administration</p>
                    <ul className="bullet-list">
                      <li>
                        Administer diluted solution intravenously via an
                        infusion pump using an in-line aseptic filter (0.2 or
                        0.22 micron).
                      </li>
                      <li>First Infusion: Infuse over at least 60 minutes.</li>
                      <li>
                        Subsequent infusions: If no infusion-related reactions
                        occurred during the first infusion, subsequent infusions
                        may be administered over 30 minutes{" "}
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("2")
                          }}
                          className="italic"
                          hash="2.2"
                          text="[see Dose Modifications (2.2)]."
                        />
                      </li>
                      <li>
                        Do not co-administer other drugs through the same
                        intravenous line.
                      </li>
                      <li>
                        When administered on the same day as chemotherapy,
                        LOQTORZI should be administered prior to chemotherapy.
                      </li>
                      <li>
                        Refer to the Prescribing Information for cisplatin and
                        gemcitabine for recommended dosing information.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ),
          },
          {
            id: "3",
            title: <span id="3">3 DOSAGE FORMS AND STRENGTHS</span>,
            content: (
              <div>
                <p>
                  Injection: 240 mg/6 mL (40 mg/mL) clear to slightly
                  opalescent, colorless to slightly yellow solution in a
                  single-dose vial.
                </p>
              </div>
            ),
          },
          {
            id: "4",
            title: <span id="4">4 CONTRAINDICATIONS</span>,
            content: (
              <div>
                <p>None.</p>
              </div>
            ),
          },
          {
            id: "5",
            title: <span id="5">5 WARNINGS AND PRECAUTIONS</span>,
            content: (
              <div>
                <div className="mb-[1rem]">
                  <div id="5.1">
                    <p className="font-MontserratSemiBold">
                      5.1 Severe and Fatal Immune-Mediated Adverse Reactions
                    </p>
                    <p>
                      LOQTORZI is a monoclonal antibody that belongs to a class
                      of drugs that bind to either the programmed death
                      receptor-1 (PD-1) or PD-ligand 1 (PD-L1), blocking the
                      PD-1/PD-L1 pathway, thereby removing inhibition of the
                      immune response, potentially breaking peripheral tolerance
                      and inducing immune-mediated adverse reactions. Important
                      immune-mediated adverse reactions listed under WARNINGS
                      AND PRECAUTIONS may not include all possible severe and
                      fatal immune-mediated reactions.
                    </p>
                    <p>
                      Immune-mediated adverse reactions, which may be severe or
                      fatal, can occur in any organ system or tissue and can
                      affect more than one body system simultaneously.
                      Immune-mediated adverse reactions can occur at any time
                      after starting PD-1/PD-L1 blocking antibody. While
                      immune-mediated adverse reactions usually manifest during
                      treatment with PD-1/PD-L1 blocking antibodies,
                      immune-mediated adverse reactions can also manifest after
                      discontinuation of PD-1/PD-L1 blocking antibodies.
                    </p>
                    <p>
                      Early identification and management of immune-mediated
                      adverse reactions are essential to ensure safe use of
                      PD-1/PD-L1 blocking antibodies. Monitor closely for
                      symptoms and signs that may be clinical manifestations of
                      underlying immune-mediated adverse reactions. Evaluate
                      liver enzymes, creatinine, and thyroid function at
                      baseline and periodically during treatment. In cases of
                      suspected immune-mediated adverse reactions, initiate
                      appropriate workup to exclude alternative etiologies,
                      including infection. Institute medical management
                      promptly, including specialty consultation as appropriate.
                    </p>
                    <p>
                      Withhold or permanently discontinue LOQTORZI depending on
                      severity{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        className="italic"
                        hash="2.2"
                        text="[see Dosage and Administration (2.2)]."
                      />{" "}
                      In general, if LOQTORZI requires interruption or
                      discontinuation, administer systemic corticosteroid
                      therapy (1 to 2 mg/kg/day prednisone or equivalent) until
                      improvement to Grade 1 or less. Upon improvement to Grade
                      1 or less, initiate corticosteroid taper and continue to
                      taper over at least 1 month. Consider administration of
                      other systemic immunosuppressants in patients whose
                      immune-mediated adverse reactions are not controlled with
                      corticosteroid therapy.
                    </p>
                    <p>
                      Toxicity management guidelines for adverse reactions that
                      do not necessarily require systemic steroids (e.g.,
                      endocrinopathies and dermatologic reactions) are discussed
                      below.
                    </p>

                    <p className="underline">Immune-Mediated Pneumonitis</p>
                    <p>
                      <em>
                        LOQTORZI in Combination with Cisplatin and Gemcitabine
                      </em>
                    </p>
                    <p>
                      LOQTORZI in combination with cisplatin and gemcitabine can
                      cause immune-mediated pneumonitis. In patients treated
                      with other PD-1/PD-L1 blocking antibodies, the incidence
                      of pneumonitis is higher in patients who have received
                      prior thoracic radiation. Immune-mediated pneumonitis
                      occurred in 2.1% (3/146) of patients receiving LOQTORZI,
                      including Grade 2 (1.4%) adverse reactions. Pneumonitis
                      resolved in 67% (2/3) of these patients.
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      LOQTORZI can cause immune-mediated pneumonitis. In
                      patients treated with other PD-1/PD-L1 blocking
                      antibodies, the incidence of pneumonitis is higher in
                      patients who have received prior thoracic radiation.
                      Immune-mediated pneumonitis occurred in 2.6% (22/851) of
                      patients receiving LOQTORZI, including fatal (0.2%), Grade
                      3 (0.7%), and Grade 2 (1.1%) adverse reactions. Systemic
                      corticosteroids were required in 82% (18/22) of patients
                      with pneumonitis. Pneumonitis led to permanent
                      discontinuation of LOQTORZI in 1.2% (10/851) of patients.
                      Pneumonitis resolved in 23% (5/22) of these patients.
                    </p>
                    <p className="underline">Immune-Mediated Colitis</p>
                    <p>
                      LOQTORZI can cause immune-mediated colitis, which may
                      present with diarrhea. Cytomegalovirus (CMV)
                      infection/reactivation has been reported in patients with
                      corticosteroid-refractory immune-mediated colitis. In
                      cases of corticosteroid-refractory colitis, consider
                      repeating infectious workup to exclude alternative
                      etiologies.
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      Immune-mediated colitis occurred in 0.4% (3/851) of
                      patients receiving LOQTORZI, including Grade 3 (0.2%) and
                      Grade 2 (0.1%) adverse reactions. Colitis resolved in all
                      3 patients.
                    </p>
                    <p className="underline">
                      Hepatotoxicity and Immune-Mediated Hepatitis
                    </p>
                    <p>
                      <em>
                        LOQTORZI in Combination with Cisplatin and Gemcitabine
                      </em>
                    </p>
                    <p>
                      LOQTORZI in combination with cisplatin and gemcitabine can
                      cause immune-mediated hepatitis. Immune-mediated hepatitis
                      occurred in 0.7% (1/146) of patients receiving LOQTORZI in
                      combination with cisplatin and gemcitabine, which was a
                      Grade 3 (0.7%) adverse reaction. The patient with
                      immune-mediated hepatitis required systemic
                      corticosteroids.
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      LOQTORZI can cause immune-mediated hepatitis.
                      Immune-mediated hepatitis occurred in 3.3% (28/851) of
                      patients receiving LOQTORZI, including Grade 4 (0.8%),
                      Grade 3 (2.1%), and Grade 2 (0.4%) adverse reactions.
                      Hepatitis led to permanent discontinuation of LOQTORZI in
                      1.1% of patients and withholding of LOQTORZI in 0.8% of
                      patients. Hepatitis resolved in 54% (15/28) of these
                      patients.
                    </p>
                    <p className="underline">
                      Immune-Mediated Endocrinopathies
                    </p>
                    <p>
                      <em>Adrenal Insufficiency</em>
                    </p>
                    <p>
                      LOQTORZI can cause primary or secondary adrenal
                      insufficiency. For Grade 2 or higher adrenal
                      insufficiency, initiate symptomatic treatment, including
                      hormone replacement as clinically indicated. Withhold or
                      permanently discontinue LOQTORZI depending on severity{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        className="italic"
                        hash="2.2"
                        text="[see Dosage and Administration (2.2)]."
                      />
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      Adrenal insufficiency occurred in 0.5% (4/851) of the
                      patients receiving LOQTORZI, including Grade 2 (0.4%) and
                      Grade 1 (0.1%) adverse reactions. Systemic corticosteroids
                      were required in 75% (3/4) of the patients with adrenal
                      insufficiency. Adrenal insufficiency led to withholding of
                      LOQTORZI in 0.1% (1/851) of patients. In the one patient
                      in whom LOQTORZI was withheld, LOQTORZI was reinitiated
                      after symptom improvement.
                    </p>
                    <p>
                      <em>Hypophysitis</em>
                    </p>
                    <p>
                      LOQTORZI can cause immune-mediated hypophysitis.
                      Hypophysitis can present with acute symptoms associated
                      with mass effects such as headache, photophobia, or visual
                      field defects. Hypophysitis can cause hypopituitarism.
                      Initiate hormone replacement as indicated. Withhold or
                      permanently discontinue LOQTORZI depending on severity{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        className="italic"
                        hash="2.2"
                        text="[see Dosage and Administration (2.2)]."
                      />
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      Hypophysitis occurred in 0.4% (3/851) of patients
                      receiving LOQTORZI, including Grade 3 (0.2%) and Grade 2
                      (0.1%) adverse reactions. All three patients received
                      systemic corticosteroids. Hypophysitis led to permanent
                      discontinuation of LOQTORZI in 0.1% (1/851) of patients
                      and withholding of LOQTORZI in 0.1% (1/851) of patients.
                      The one patient in whom LOQTORZI was withheld reinitiated
                      LOQTORZI.
                    </p>
                    <p>
                      <em>Thyroid Disorders</em>
                    </p>
                    <p>
                      LOQTORZI can cause immune-mediated thyroid disorders.
                      Thyroiditis can present with or without endocrinopathy.
                      Hypothyroidism can follow hyperthyroidism. Initiate
                      hormone replacement for hypothyroidism or institute
                      medical management of hyperthyroidism as clinically
                      indicated. Withhold or permanently discontinue LOQTORZI
                      depending on severity{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        className="italic"
                        hash="2.2"
                        text="[see Dosage and Administration (2.2)]."
                      />
                    </p>
                    <p>
                      <em>
                        LOQTORZI in Combination with Cisplatin and Gemcitabine
                      </em>
                    </p>
                    <p>
                      Thyroiditis occurred in 2.1% (3/146) of patients receiving
                      LOQTORZI in combination with cisplatin and gemcitabine,
                      including Grade 2 (1.4%). Three patients required thyroid
                      hormone replacement therapy. Thyroiditis resolved in one
                      of the 3 patients.
                    </p>
                    <p>
                      Hyperthyroidism occurred in 1.4% (2/146) of patients
                      receiving LOQTORZI in combination with cisplatin and
                      gemcitabine. Hyperthyroidism resolved in these 2 patients.
                    </p>
                    <p>
                      Hypothyroidism occurred in 30% (44/146) of patients
                      receiving LOQTORZI in combination with cisplatin and
                      gemcitabine, including Grade 2 (24%) and Grade 1 (6%).
                      Eighty percent of the 44 patients required thyroid hormone
                      replacement therapy. LOQTORZI was withheld in 2.1% (3/146)
                      of the patients. Of the 3 patients in whom LOQTORZI was
                      withheld, 2 patients reinitiated LOQTORZI.
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      Thyroiditis occurred in 0.6% (5/851) of patients receiving
                      LOQTORZI, including Grade 2 (0.1%). Two of these 5
                      patients received systemic corticosteroids and 2 required
                      thyroid hormone replacement therapy. Thyroiditis resolved
                      in 2 of the 5 patients.
                    </p>
                    <p>
                      Hyperthyroidism occurred in 7% (55/851) of patients
                      receiving LOQTORZI, including Grade 2 (1.9%).
                      Hyperthyroidism resolved in 85% (47/55) of the patients.
                    </p>
                    <p>
                      Hypothyroidism occurred in 15% (128/851) of patients
                      receiving LOQTORZI, including Grade 2 (8%). Sixty three
                      percent of the 128 patients required thyroid hormone
                      replacement therapy. LOQTORZI was withheld in 0.5% of
                      patients. Of the 4 patients in whom LOQTORZI was withheld,
                      3 patients reinitiated LOQTORZI.
                    </p>
                    <p>
                      <em>
                        Type 1 Diabetes Mellitus, which can present with
                        Diabetic Ketoacidosis
                      </em>
                    </p>
                    <p>
                      Monitor patients for hyperglycemia or other signs and
                      symptoms of diabetes. Initiate treatment with insulin as
                      clinically indicated. Withhold or permanently discontinue
                      LOQTORZI depending on severity{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        className="italic"
                        hash="2.2"
                        text="[see Dosage and Administration (2.2)]."
                      />
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      Diabetes mellitus occurred in 0.9% (8/851) of patients
                      receiving LOQTORZI, including Grade 4 (0.1%), Grade 3
                      (0.7%), and Grade 2 (0.1%). Diabetes mellitus led to
                      permanent discontinuation in 0.4% of patients. Six of the
                      8 (75%) patients with diabetes mellitus required long-term
                      insulin therapy.
                    </p>
                    <p className="underline">
                      Immune-Mediated Nephritis with Renal Dysfunction
                    </p>
                    <p>
                      <em>
                        LOQTORZI in Combination with Cisplatin and Gemcitabine
                      </em>
                    </p>
                    <p>
                      LOQTORZI in combination with cisplatin and gemcitabine can
                      cause immune-mediated nephritis. Immune-mediated nephritis
                      occurred in 0.7% (1/146) of patients receiving LOQTORZI.
                      The one patient with immune-mediated nephritis (Grade 4)
                      required systemic corticosteroids and nephritis led to
                      discontinuation of LOQTORZI. Nephritis resolved in this
                      patient.
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      LOQTORZI can cause immune-mediated nephritis.
                      Immune-mediated nephritis occurred in 0.5% (4/851) of
                      patients receiving LOQTORZI, including Grade 3 (0.5%)
                      adverse reactions. Nephritis resolved in 75% (3/4) of
                      these patients.
                    </p>
                    <p className="underline">
                      Immune-Mediated Dermatologic Adverse Reactions
                    </p>
                    <p>
                      LOQTORZI can cause immune-mediated rash or dermatitis.
                      Exfoliative dermatitis, including Stevens-Johnson Syndrome
                      (SJS), drug rash with eosinophilia and systemic symptoms
                      (DRESS), and toxic epidermal necrolysis (TEN), has
                      occurred with PD-1/PD-L1 blocking antibodies. Topical
                      emollients and/or topical corticosteroids may be adequate
                      to treat mild to moderate non-exfoliative rashes. Withhold
                      or permanently discontinue LOQTORZI depending on severity{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        className="italic"
                        hash="2.2"
                        text="[see Dosage and Administration (2.2)]."
                      />
                    </p>
                    <p>
                      <em>
                        LOQTORZI in Combination with Cisplatin and Gemcitabine
                      </em>
                    </p>
                    <p>
                      Immune-mediated dermatologic adverse reactions occurred in
                      8% (12/146) of patients receiving LOQTORZI, including
                      Grade 3 (3.4%) and Grade 2 (1.4%) adverse reactions.
                      Systemic corticosteroids were required in 25% (3/12) of
                      the patients with immune-mediated dermatologic adverse
                      reactions. Immune-mediated dermatologic adverse reactions
                      led to permanent discontinuation of LOQTORZI in 2.1% (3)
                      of patients. Immune-mediated dermatologic adverse
                      reactions resolved in 92% (11/12) of these patients.
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      Immune-mediated dermatologic adverse reactions occurred in
                      4% (34/851) of patients receiving LOQTORZI, including
                      Grade 3 (0.4%) and Grade 2 (1.4%) adverse reactions.
                      Immune-mediated dermatologic adverse reactions led to
                      withholding of LOQTORZI in 0.4% (3) of the patients.
                      Systemic corticosteroids were required in 12% (4/34) of
                      the patients with immune-mediated dermatologic adverse
                      reactions. Immune-mediated dermatologic adverse reactions
                      resolved in 71% (24/34) of these patients.
                    </p>
                    <p className="underline">
                      Other Immune-Mediated Adverse Reactions
                    </p>
                    <p>
                      The following clinically significant immune-mediated
                      adverse reactions occurred at an incidence of &lt;1%
                      (unless otherwise noted) in patients who received LOQTORZI
                      or were reported with the use of other PD1/PD-L1 blocking
                      antibodies. Severe or fatal cases have been reported for
                      some of these adverse reactions.
                    </p>
                    <p>
                      <em>Cardiac/Vascular: </em>
                      Myocarditis, pericarditis, vasculitis, pericardial
                      effusion
                    </p>
                    <p>
                      <em>Nervous System: </em>
                      Meningitis, encephalitis, myelitis and demyelination,
                      myasthenic syndrome/myasthenia gravis (including
                      exacerbation), Guillain-Barré syndrome, nerve paresis,
                      autoimmune neuropathy
                    </p>
                    <p>
                      <em>Ocular: </em>
                      Uveitis, iritis and other ocular inflammatory toxicities
                      can occur. Some cases can be associated with retinal
                      detachment. Various grades of visual impairment, including
                      blindness, can occur. If uveitis occurs in combination
                      with other immune-mediated adverse reactions, consider a
                      Vogt-Koyanagi-Harada-like syndrome, as this may require
                      treatment with systemic steroids to reduce the risk of
                      permanent vision loss.
                    </p>
                    <p>
                      <em>Gastrointestinal: </em>
                      Pancreatitis, to include increases in serum amylase and
                      lipase levels, gastritis, duodenitis
                    </p>
                    <p>
                      <em>Musculoskeletal and Connective Tissue: </em>
                      Myositis/polymyositis, rhabdomyolysis (and associated
                      sequelae, including renal failure), arthritis, polymyalgia
                      rheumatica, dermatomyositis
                    </p>
                    <p>
                      <em>Endocrine: </em>
                      Hypoparathyroidism
                    </p>
                    <p className={styles.verticalRule}>
                      <em>Hematologic/Immune: </em>
                      Hemolytic anemia, aplastic anemia, hemophagocytic
                      lymphohistiocytosis, systemic inflammatory response
                      syndrome, histiocytic necrotizing lymphadenitis (Kikuchi
                      lymphadenitis), sarcoidosis, immune thrombocytopenic
                      purpura, solid organ transplant rejection, other
                      transplant (including corneal graft) rejection.
                    </p>
                  </div>
                </div>
                <div className="mb-[1rem]">
                  <div id="5.2">
                    <p className={styles.subHeader}>
                      5.2 Infusion-Related Reactions
                    </p>
                    <p>
                      LOQTORZI can cause severe or life-threatening
                      infusion-related reactions including hypersensitivity and
                      anaphylaxis.
                    </p>
                    <p>
                      <em>
                        LOQTORZI in Combination with Cisplatin and Gemcitabine
                      </em>
                    </p>
                    <p>
                      Infusion-related reactions have been reported in 4.1% of
                      patients receiving LOQTORZI in combination with cisplatin
                      and gemcitabine, including Grade 2 (0.7%) reactions.
                    </p>
                    <p>
                      <em>LOQTORZI as a Single-Agent</em>
                    </p>
                    <p>
                      Infusion-related reactions occurred in 2% of 851 patients
                      receiving LOQTORZI as single agent, including Grade 3
                      (0.1%) and Grade 2 (0.6%). LOQTORZI was withheld for one
                      Grade 3 infusion related reaction.
                    </p>
                    <p>
                      Monitor patients for signs and symptoms of
                      infusion-related reactions including rigors, chills,
                      wheezing, pruritus, flushing, rash, hypotension,
                      hypoxemia, and fever. Interrupt or slow the rate of
                      infusion for mild (Grade 1) or moderate (Grade 2)
                      infusion-related reactions. For severe (Grade 3) or
                      life-threatening (Grade 4) infusion-related reactions,
                      stop infusion and permanently discontinue LOQTORZI{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("2")
                        }}
                        className="italic"
                        hash="2.2"
                        text="[see Dosage and Administration (2.2)]."
                      />
                    </p>
                  </div>
                </div>
                <div className="mb-[1rem]">
                  <div id="5.3">
                    <p className={styles.subHeader}>
                      5.3 Complications of Allogeneic HSCT
                    </p>
                    <p>
                      Fatal and other serious complications can occur in
                      patients who receive allogeneic hematopoietic stem cell
                      transplantation (HSCT) before or after being treated with
                      a PD-1/PD-L1 blocking antibody. Transplant-related
                      complications include hyperacute graft-versus-host-disease
                      (GVHD), acute GVHD, chronic GVHD, hepatic veno-occlusive
                      disease (VOD) after reduced intensity conditioning, and
                      steroid-requiring febrile syndrome (without an identified
                      infectious cause). These complications may occur despite
                      intervening therapy between PD-1/PD-L1 blockade and
                      allogeneic HSCT.
                    </p>
                    <p>
                      Follow patients closely for evidence of transplant-related
                      complications and intervene promptly. Consider the benefit
                      versus risks of treatment with a PD-1/PD-L1 blocking
                      antibody prior to or after an allogeneic HSCT.
                    </p>
                  </div>
                </div>
                <div className="mb-[1rem]">
                  <div id="5.4">
                    <p className={styles.subHeader}>
                      5.4 Embryo-Fetal Toxicity
                    </p>
                    <p>
                      Based on its mechanism of action, LOQTORZI can cause fetal
                      harm when administered to a pregnant woman. Animal studies
                      have demonstrated that inhibition of the PD-1/PD-L1
                      pathway can lead to increased risk of immune-mediated
                      rejection of the developing fetus resulting in fetal
                      death. Advise women of the potential risk to a fetus.
                      Advise females of reproductive potential to use effective
                      contraception during treatment with LOQTORZI and for 4
                      months after the last dose{" "}
                      <span className="text-blue-200 italic">
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("8")
                          }}
                          className="italic"
                          hash="8.1"
                          text="[see Use in Specific Populations"
                        />{" "}
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("8")
                          }}
                          hash="8.1"
                          text="(8.1"
                        />
                        ,{" "}
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("8")
                          }}
                          hash="8.3"
                          text="8.3)]"
                        />
                      </span>
                      .
                    </p>
                  </div>
                </div>
              </div>
            ),
          },
          {
            id: "6",
            title: <span id="6">6 ADVERSE REACTIONS</span>,
            content: (
              <div>
                <p>
                  The following clinically significant adverse reactions are
                  described elsewhere in the labeling:
                </p>
                <ul className="bullet-list mt-[1em]">
                  <li>
                    Severe and fatal immune-mediated adverse reactions{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("5")
                      }}
                      className="italic"
                      hash="5.1"
                      text="[see Warnings and Precautions (5.1)]"
                    />{" "}
                  </li>
                  <li>
                    Infusion-related reactions{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("5")
                      }}
                      className="italic"
                      hash="5.1"
                      text="[see Warnings and Precautions (5.2)]"
                    />
                  </li>
                  <li>
                    Complications of Allogeneic HSCT{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("5")
                      }}
                      className="italic"
                      hash="5.1"
                      text="[see Warnings and Precautions (5.3)]"
                    />
                  </li>
                </ul>
                <div className="mt-[2rem] mb-[1rem]">
                  <div id="6.1">
                    <p className={styles.subHeader}>
                      6.1 Clinical Trials Experience
                    </p>
                    <p>
                      Because clinical trials are conducted under widely varying
                      conditions, adverse reaction rates observed in the
                      clinical trials of a drug cannot be directly compared to
                      rates in the clinical trials of another drug and may not
                      reflect the rates observed in practice.
                    </p>
                    <p>
                      The data described in the WARNINGS AND PRECAUTIONS section
                      reflect exposure to LOQTORZI at a dose of 240 mg every 3
                      weeks in combination with up to 6 cycles of cisplatin and
                      gemcitabine, followed by LOQTORZI 240 mg IV every 3 weeks,
                      in 146 patients with NPC enrolled in a randomized,
                      double-blind, placebo-controlled trial (JUPITER-02). Among
                      the 146 patients, 73% were exposed to LOQTORZI for 6
                      months or more and 54% were exposed for 12 months or more.
                      The most common adverse reactions (≥ 20%) were: nausea
                      (71%), vomiting (68%), decreased appetite (55%),
                      constipation (39%), hypothyroidism (38%), rash (36%),
                      pyrexia (32%), diarrhea (31%), peripheral neuropathy
                      (30%), cough (26%), musculoskeletal pain (25%), upper
                      respiratory infection (23%), insomnia (23%), dizziness
                      (21%), and malaise (21%). The most common Grade 3 or 4
                      laboratory abnormalities (≥2%) were: decreased neutrophils
                      (58%), decreased lymphocytes (57%), decreased hemoglobin
                      (50%), decreased platelets (33%), decreased potassium
                      (10%), decreased sodium (9%), increased alanine
                      aminotransferase (6%) increased or decreased magnesium
                      (4.2% each), decreased calcium (3.5%), increased aspartate
                      aminotransferase (2.7%), and bilirubin increased (2.1%).
                    </p>
                    <p>
                      The data described in the WARNINGS AND PRECAUTIONS section
                      also reflects exposure to LOQTORZI as a single agent at a
                      dose of 3 mg/kg IV every 2 weeks in 851 patients enrolled
                      in 12 trials: one randomized, active-controlled trial and
                      11 open-label, non-randomized trials. The tumor types
                      included nasopharyngeal carcinoma (n=193) or other types
                      of tumors (n=658). Among the 851 patients treated with
                      LOQTORZI as a single agent, 35% were exposed for 6 months
                      or more and 20% were exposed for 12 months or more. In
                      this pooled safety population, the most common (≥20%)
                      adverse reactions were: fatigue (22%), hypothyroidism
                      (20%), and musculoskeletal pain (20%). The most common
                      Grade 3 or 4 laboratory abnormalities (≥2%), were
                      decreased sodium (9%), decreased lymphocytes (8%),
                      decreased hemoglobin (7%), decreased fibrinogen (4.5%),
                      increased lipase (4.0%), increased amylase (2.9%),
                      decreased phosphate (2.8%), increased aspartate
                      aminotransferase (2.6%), increased glucose (2.5%), and
                      increased triglycerides (2.1%).
                    </p>
                    <p className="underline">
                      First-line Treatment of Metastatic or Recurrent, Locally
                      Advanced Nasopharyngeal Carcinoma (NPC)
                    </p>
                    <p>
                      The safety of LOQTORZI in combination with cisplatin and
                      gemcitabine was evaluated in JUPITER-02{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("14")
                        }}
                        className="italic"
                        hash="14"
                        text="[see Clinical Studies (14)]"
                      />
                      . Key eligibility criteria were recurrent locally advanced
                      or metastatic nasopharyngeal carcinoma (NPC) not
                      previously treated with systemic chemotherapy for
                      recurrent or metastatic disease. Patients with recurrent
                      NPC after treatment with curative intent were required to
                      have an interval of at least 6 months between the last
                      dose of radiotherapy or chemotherapy and recurrence.
                      Patients received LOQTORZI 240 mg (n=146) or placebo
                      intravenously (IV) every 3 weeks (n=143), in combination
                      with cisplatin 80 mg/m<sup>2</sup> IV every 3 weeks and
                      gemcitabine 1000 mg/m<sup>2</sup> IV days 1 and 8 for up
                      to 6 cycles followed by LOQTORZI 240 mg or placebo IV
                      every 3 weeks until disease progression, unacceptable
                      toxicity, or completion of 2 years of treatment. Among
                      patients who received LOQTORZI, 73% were exposed for 6
                      months or longer and 54% were exposed for greater than one
                      year.
                    </p>
                    <p>
                      The median age of patients who received LOQTORZI was 48
                      years (range: 19 to 72), 83% male, 100% Asian, 60% had
                      recurrent disease, and 40% presented with metastatic
                      disease. Eastern Cooperative Oncology Group (ECOG)
                      Performance Status (PS) was 0 (57%) or 1 (43%).
                      Approximately 59% of patients had received at least one
                      prior systemic therapy for locally advanced disease and
                      60% had received prior radiation therapy.
                    </p>
                    <p>
                      Serious adverse reactions occurred in 43% of patients
                      receiving LOQTORZI in combination with cisplatin and
                      gemcitabine. Serious adverse drug reactions in ≥ 2% were
                      thrombocytopenia (14%), neutrophil count decreased (10%),
                      pneumonia (10%), anemia (9%), abnormal hepatic function
                      (2.7%), and rash (2.1%). Of the patients who received
                      LOQTORZI in combination with cisplatin and gemcitabine,
                      there were three fatal adverse reactions (2.1%) one due to
                      epistaxis; one due to intracranial hemorrhage associated
                      with immune-related thrombocytopenia and coagulopathy; and
                      one due to pneumonia.
                    </p>
                    <p>
                      Permanent discontinuation of LOQTORZI, when given in
                      combination with cisplatin and gemcitabine, due to an
                      adverse reaction occurred in 12% of patients. Adverse
                      reactions resulting in permanent discontinuation of
                      LOQTORZI in ≥1% were pneumonia (2.1%), pulmonary
                      tuberculosis (1.4%), rash (1.4%), and vomiting (1.4%).
                    </p>
                    <p>
                      Dosage interruptions of LOQTORZI due to an adverse
                      reaction occurred in 50% of patients. Adverse reactions
                      which required dosage interruption in ≥2% were anemia
                      (17%), decreased neutrophils (12%), thrombocytopenia
                      (12%), acute kidney injury (4.1%), pneumonia (6%), fatigue
                      (2.7%), upper respiratory infection (2.7%), and
                      hypothyroidism (2.1%).
                    </p>
                    <p>
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("6")
                        }}
                        hash="table-3"
                        text="Table 3"
                      />{" "}
                      summarizes the adverse reactions in JUPITER-02.
                    </p>
                    <p id="table-3" className="font-MontserratSemiBold">
                      Table 3: Adverse Reactions (≥10%) in Patients with
                      Recurrent, Locally Advanced or Metastatic NPC Who Received
                      LOQTORZI in Combination with Cisplatin and Gemcitabine in
                      JUPITER-02
                    </p>
                    <img
                      className="w-full h-auto"
                      src={PiTable3}
                      alt="Table 3"
                    />

                    <Footnotes
                      className="mt-[1em] !text-[1.2rem]"
                      items={[
                        {
                          bullet: <sup>1</sup>,
                          text: <span>NCI CTCAE v5.0.</span>,
                        },
                        {
                          bullet: <sup>2</sup>,
                          text: (
                            <span>
                              Includes mouth ulceration, stomatitis, and
                              radiation stomatitis.
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>3</sup>,
                          text: (
                            <span>
                              Includes hypothyroidism, tri-iodothyronine
                              decreased, tri-iodothyronine free decreased, and
                              thyroiditis.
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>4</sup>,
                          text: (
                            <span>
                              Includes acneiform dermatitis, allergic
                              dermatitis, catheter-site rash, dermatitis, drug
                              eruption, eczema, erythema, macule, maculopapular
                              rash, palmar-plantar erythrodysesthesia syndrome,
                              papule, pruritic rash, rash, and urticaria.
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>5</sup>,
                          text: <span>Includes asthenia and fatigue.</span>,
                        },
                        {
                          bullet: <sup>6</sup>,
                          text: (
                            <span>
                              Includes hypoesthesia, neuralgia, neuropathy
                              peripheral, paresthesia, peripheral sensory
                              neuropathy.
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>7</sup>,
                          text: (
                            <span>Includes cough and productive cough.</span>
                          ),
                        },
                        {
                          bullet: <sup>8</sup>,
                          text: (
                            <span>
                              Includes back pain, bone pain, musculoskeletal
                              chest pain, musculoskeletal pain, myalgia, neck
                              pain, pain in extremity, pain in jaw.
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>9</sup>,
                          text: (
                            <span>
                              Includes acute sinusitis, bronchitis, laryngitis,
                              nasopharyngitis, pharyngitis, respiratory tract
                              infection, rhinitis, sinusitis, and upper
                              respiratory tract infection.
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>10</sup>,
                          text: (
                            <span>
                              Includes aspiration pneumonia and pneumonia
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>11</sup>,
                          text: (
                            <span>
                              Includes blood pressure increased, blood pressure
                              systolic increased, hypertension, and hypertensive
                              crisis.
                            </span>
                          ),
                        },
                      ]}
                    />
                    <p className="mt-[1em]">
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("6")
                        }}
                        hash="table-4"
                        text="Table 4"
                      />{" "}
                      summarizes the laboratory abnormalities in JUPITER-02.
                    </p>
                    <p id="table-4" className="font-MontserratSemiBold">
                      Table 4: Select Laboratory Abnormalities (≥20%) That
                      Worsened from Baseline in Patients with Recurrent, Locally
                      Advanced or Metastatic NPC Who Received LOQTORZI in
                      Combination with Cisplatin and Gemcitabine in JUPITER-02
                    </p>
                    <img
                      className="w-full h-auto"
                      src={PiTable4}
                      alt="Table 4"
                    />

                    <Footnotes
                      className={"mt-[1.2rem] mb-[2rem] !text-[1.2rem]"}
                      items={[
                        {
                          bullet: "*",
                          text: (
                            <span>
                              Each test incidence is based on the number of
                              patients who had both baseline and at least one
                              on-study laboratory measurement available:
                              LOQTORZI/chemotherapy (range: 139 to 146 patients)
                              and placebo/chemotherapy (range: 136 to 143
                              patients). † Graded per NCI CTCAE v5.0;
                              AKP=alkaline phosphatase. ALT=alanine
                              aminotransferase. AST=aspartate aminotransferase.
                            </span>
                          ),
                        },
                      ]}
                    />
                    <p className="mb-0 underline">
                      Previously Treated, Unresectable or Metastatic
                      Nasopharyngeal Carcinoma (NPC)
                    </p>
                    <p>
                      The safety of LOQTORZI was evaluated in POLARIS-02.
                      Eligible patients had previously treated unresectable or
                      metastatic NPC. Patients received LOQTORZI 3 mg/kg every 2
                      weeks as an intravenous infusion until disease progression
                      or unacceptable toxicity. Among patients who received
                      LOQTORZI, 33% were exposed for 6 months or longer and 21%
                      were exposed for greater than one year.{" "}
                    </p>
                    <p>
                      The median age of patients who received LOQTORZI was 46
                      years (range: 22 to 71), 83% male, 100% Asian, Eastern
                      Cooperative Oncology Group (ECOG) Performance Status (PS)
                      of 0 (35%) or 1 (65%) and median weight 59 kg (range: 32
                      to 101 kg).
                    </p>
                    <p>
                      Serious adverse reactions occurred in 24% of patients who
                      received LOQTORZI. Serious adverse drug reactions in (≥2%)
                      were pneumonia (4.7%), abnormal hepatic function (2.6%),
                      and hyperbilirubinemia (2.1%). Fatal adverse reactions
                      occurred in 3.7% of patients who received LOQTORZI,
                      including death not otherwise specified (1.6%), tumor
                      hemorrhage (0.5%), hepatic failure and thrombocytopenia
                      (0.5%), hyponatremia (0.5%), and sudden death (0.5%).
                    </p>
                    <p>
                      Permanent discontinuation of LOQTORZI due to an adverse
                      reaction occurred in 9% of patients. Adverse reaction
                      resulting in permanent discontinuation of LOQTORZI in ≥1%
                      included pneumonia (1.1%), abnormal hepatic function
                      (1.1%), and hyperbilirubinemia (1.1%).
                    </p>
                    <p>
                      Dosage interruptions due to an adverse reaction occurred
                      in 23% of patients. Adverse reactions which required
                      dosage interruption in ≥1% were pneumonia (2.1%),
                      thrombocytopenia (2.1%), fatigue (1.6%),
                      hyperbilirubinemia (1.6%), anemia (1.1%), decreased
                      appetite (1.1%), abnormal hepatic function (1.1%),
                      hypothyroidism (1.1%), and pneumonitis (1.1%).
                    </p>
                    <p>
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("6")
                        }}
                        hash="table-5"
                        text="Table 5"
                      />{" "}
                      summarizes the adverse reactions in POLARIS-02.
                    </p>
                    <p id="table-5" className="font-MontserratSemiBold">
                      Table 5: Adverse Reactions (≥10%) in Patients with
                      Previously Treated, Unresectable or Metastatic NPC Who
                      Received LOQTORZI in POLARIS-02
                    </p>
                    <img
                      className="w-full h-auto"
                      src={PiTable5}
                      alt="Table 5"
                    />

                    <Footnotes
                      className={"mt-[1.3rem] !text-[1.2rem]"}
                      items={[
                        {
                          bullet: "*",
                          text: (
                            <span>
                              Toxicity was graded per National Cancer Institute
                              Common Terminology Criteria for Adverse Events
                              (NCI CTCAE) v4.03.
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>1</sup>,
                          text: (
                            <span>
                              Includes hypothyroidism, thyroiditis,
                              tri-iodothyronine decreased, and tri-iodothyronine
                              free decreased
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>2</sup>,
                          text: <span>Includes fatigue and asthenia</span>,
                        },
                        {
                          bullet: <sup>3</sup>,
                          text: (
                            <span>Includes cough and productive cough.</span>
                          ),
                        },
                        {
                          bullet: <sup>4</sup>,
                          text: (
                            <span>
                              Includes musculoskeletal pain and myalgia.
                            </span>
                          ),
                        },
                        {
                          bullet: <sup>5</sup>,
                          text: (
                            <span>
                              Includes dermatitis allergic, eczema, and rash.
                            </span>
                          ),
                        },
                      ]}
                    />

                    <p className="mt-[1em]">
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("6")
                        }}
                        hash="table-6"
                        text="Table 6"
                      />{" "}
                      summarizes the laboratory abnormalities in POLARIS-02.
                    </p>
                    <p id="table-6" className="font-MontserratSemiBold">
                      Table 6: Select Laboratory Abnormalities (≥20%) That
                      Worsened from Baseline in Patients with Previously
                      Treated, Unresectable or Metastatic NPC Who Received
                      LOQTORZI in POLARIS-02
                    </p>
                    <img
                      className="w-full h-auto"
                      src={PiTable6}
                      alt="Table 6"
                    />

                    <Footnotes
                      className={" mt-[1.3rem] !text-[1.2rem]"}
                      items={[
                        {
                          bullet: <sup>1</sup>,
                          text: (
                            <span>
                              Toxicity graded per NCI CTCAE v4.03. The
                              denominator used to calculate the rate varied from
                              141 to 186 based on the number of patients with a
                              baseline value and at least one post-treatment
                              value.
                            </span>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            ),
          },
          {
            id: "8",
            title: <span id="8">8 USE IN SPECIFIC POPULATIONS</span>,
            content: (
              <div>
                <div id="8.1" className="mt-[2rem] mb-[1rem]">
                  <p className="font-MontserratSemiBold">8.1 Pregnancy</p>
                  <p className="underline">Risk Summary</p>
                  <p>
                    Based on its mechanism of action, LOQTORZI can cause fetal
                    harm when administered to a pregnant woman{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("12")
                      }}
                      className="italic"
                      hash="12.1"
                      text={<>[see Clinical Pharmacology (12.1)]</>}
                    />
                    . There are no available data on the use of LOQTORZI in
                    pregnant women. Animal studies have demonstrated that
                    inhibition of the PD-1/PD-L1 pathway can lead to increased
                    risk of immune-mediated rejection of the developing fetus
                    and result in fetal death{" "}
                    <span className="text-blue-200 italic">(see Data)</span>.
                    Human IgG4 immunoglobulins (IgG4) are known to cross the
                    placenta; therefore, LOQTORZI can potentially be transmitted
                    from the mother to the developing fetus. Advise women of the
                    potential risk to a fetus.
                  </p>
                  <p>
                    In the U.S. general population, the estimated background
                    risk of major birth defects and miscarriage in clinically
                    recognized pregnancies is 2% to 4% and 15% to 20%,
                    respectively.
                  </p>
                  <p className="underline">Data</p>
                  <p className="font-MontserratItalic">Animal Data</p>
                  <p>
                    Animal reproduction studies have not been conducted with
                    LOQTORZI to evaluate its effect on reproduction and fetal
                    development. A central function of the PD-1/PD-L1 pathway is
                    to preserve pregnancy by maintaining maternal immune
                    tolerance to the fetus. In murine models of pregnancy,
                    blockade of PD-L1 signaling has been shown to disrupt
                    tolerance to the fetus and to result in an increase in fetal
                    loss; therefore, potential risks of administering LOQTORZI
                    during pregnancy could include increased rates of abortion
                    or stillbirth. As reported in the literature, there were no
                    malformations related to the blockade of PD-1/PD-L1
                    signaling in the offspring of these animals; however,
                    immune-mediated disorders occurred in PD-1 and PD-L1
                    knockout mice. Based on its mechanism of action, fetal
                    exposure to toripalimab-tpzi may increase the risk of
                    developing immune-mediated disorders or altering the normal
                    immune response.
                  </p>
                </div>
                <div id="8.2" className="mt-[2rem] mb-[1rem]">
                  <p className="font-MontserratSemiBold">8.2 Lactation</p>
                  <p className="underline">Risk Summary</p>
                  <p>
                    There are no data on the presence of toripalimab-tpzi in
                    human milk or its effects on the breastfed child or on milk
                    production. Maternal IgG is known to be present in human
                    milk. The effects of local gastrointestinal exposure and
                    limited systemic exposure in the breastfed child to
                    toripalimab-tpzi are unknown. Because of the potential for
                    serious adverse reactions in breastfed children, advise
                    lactating women not to breastfeed during treatment with
                    LOQTORZI and for 4 months after the last dose.
                  </p>
                </div>
                <div id="8.3" className="mt-[2rem] mb-[1rem]">
                  <p className="font-MontserratSemiBold">
                    8.3 Females and Males of Reproductive Potential
                  </p>
                  <p>
                    LOQTORZI can cause fetal harm when administered to a
                    pregnant woman{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("8")
                      }}
                      className="italic"
                      hash="8.1"
                      text="[see Use in Specific Populations (8.1)]"
                    />
                    .{" "}
                  </p>
                  <p className="underline">Pregnancy Testing</p>
                  <p>
                    Verify the pregnancy status of females of reproductive
                    potential prior to initiating LOQTORZI{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("8")
                      }}
                      className="italic"
                      hash="8.1"
                      text="[see Use in Specific Populations (8.1)]"
                    />
                    .
                  </p>
                  <p className="underline">Contraception</p>
                  <p>
                    <em>Females</em>
                  </p>
                  <p>
                    Advise females of reproductive potential to use effective
                    contraception during treatment with LOQTORZI and for 4
                    months after the last dose.
                  </p>
                </div>

                <div id="8.4" className="mt-[2rem] mb-[1rem]">
                  <p className="font-MontserratSemiBold">8.4 Pediatric Use</p>
                  <p>
                    The safety and effectiveness of LOQTORZI have not been
                    established in pediatric patients{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("1")
                      }}
                      className="italic"
                      hash="1"
                      text="[see Indications and Usage (1)]"
                    />
                    .
                  </p>
                </div>

                <div id="8.5" className="mt-[2rem] mb-[1rem]">
                  <p className="font-MontserratSemiBold">8.5 Geriatric Use</p>
                  <p>
                    Of the 146 patients with NPC who were treated with LOQTORZI
                    in combination with cisplatin and gemcitabine 7 (4.8%) were
                    65 years or older; there were no patients 75 years and
                    older. Clinical studies of LOQTORZI in combination with
                    cisplatin and gemcitabine did not include a sufficient
                    number of patients aged 65 years and over with NPC to
                    determine whether they respond differently from younger
                    patients.
                  </p>
                  <p>
                    Of the 851 patients with tumor types including
                    nasopharyngeal carcinoma or other types of tumors from the
                    safety pool treated with LOQTORZI as a single agent, 171
                    (20%) patients were 65 years or older and 13 (1.5%) patients
                    were 75 years and older. No overall differences in safety
                    were observed between elderly patients and younger patients{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("6")
                      }}
                      className="italic"
                      hash="6.1"
                      text="[see Adverse Reactions (6.1)]"
                    />
                    .
                  </p>
                  <p>
                    Of the 190 patients with NPC treated with LOQTORZI as single
                    agent, 10 (5%) patients were 65 years or older; there were
                    no patients 75 years and older. Clinical studies of LOQTORZI
                    did not include sufficient numbers of patients aged 65 years
                    and over with NPC to determine whether they respond
                    differently from younger patients.
                  </p>
                </div>
              </div>
            ),
          },
          {
            id: "11",
            title: <span id="11">11 DESCRIPTION</span>,
            content: (
              <div>
                <p>
                  Toripalimab-tpzi is a programmed death receptor-1 (PD-1)
                  blocking antibody. Toripalimab-tpzi is a humanized
                  immunoglobulin G4 (IgG4) kappa immunoglobulin that has a
                  predicted molecular weight of approximately 150 kDa. It is
                  expressed in a recombinant Chinese Hamster Ovary (CHO)
                  mammalian cell expression system.
                </p>
                <p>
                  LOQTORZI (toripalimab-tpzi) injection is a sterile,
                  preservative-free, clear to slightly opalescent, colorless to
                  slightly yellow solution for intravenous use supplied in a
                  single-dose vial. Each vial contains 240 mg of LOQTORZI in 6
                  mL of solution. Each mL of solution contains 40 mg
                  toripalimab-tpzi, citric acid monohydrate (0.51 mg), mannitol
                  (25 mg), polysorbate 80 (0.20 mg), sodium chloride (2.92 mg),
                  sodium citrate (4.65 mg), and Water for Injection, USP, at pH
                  6.0.{" "}
                </p>
              </div>
            ),
          },
          {
            id: "12",
            title: <span id="12">12 CLINICAL PHARMACOLOGY</span>,
            content: (
              <div>
                <div id="12.1" className="mt-[2rem] mb-[1rem]">
                  <p className={styles.subHeader}>12.1 Mechanism of Action</p>
                  <p>
                    Binding of the PD-1 ligands, PD-L1 and PD-L2, to the PD-1
                    receptor found on T cells, inhibits T cell proliferation and
                    cytokine production. Upregulation of PD-1 ligands occurs in
                    some tumors and signaling through this pathway can
                    contribute to inhibition of active T-cell immune
                    surveillance of tumors. Toripalimab-tpzi is a humanized IgG4
                    monoclonal antibody that binds to the PD-1 receptor and
                    blocks its interaction with PD-L1 and PD-L2, releasing PD-1
                    pathway-mediated inhibition of the immune response,
                    including the anti-tumor immune response. In syngeneic mouse
                    tumor models, blocking PD-1 activity resulted in decreased
                    tumor growth.
                  </p>
                </div>

                <div id="12.2" className="mt-[2rem] mb-[1rem]">
                  <p className={styles.subHeader}>12.2 Pharmacodynamics</p>
                  <p>
                    The toripalimab-tpzi exposure-response relationships and
                    time course of pharmacodynamic response are not fully
                    characterized.
                  </p>
                </div>

                <div id="12.3" className="mt-[2rem] mb-[1rem]">
                  <p className={styles.subHeader}>12.3 Pharmacokinetics</p>
                  <p>
                    Toripalimab-tpzi pharmacokinetic parameters are presented as
                    geometric mean (coefficient of variation [CV]%) unless
                    otherwise noted. Toripalimab-tpzi concentrations increased
                    in non-linearly over the dose range of 0.3 to 10 mg/kg every
                    two weeks (0.1 to 3.3 times the approved recommended 3 mg/kg
                    dosage in a 64 kg patient). Steady state was reached by Week
                    7. The mean accumulation ratio was approximately 1.4 for
                    maximum concentration (C<sub>max</sub>) and 1.9 for area
                    under the serum concentration curve (AUC) following multiple
                    doses at the approved recommended dosages of 240 mg Q3W in
                    combination with cisplatin and gemcitabine and 3 mg/kg Q2W
                    as monotherapy.
                  </p>
                  <p className="underline">Distribution</p>
                  <p>
                    The mean volume of distribution at steady state (V
                    <sub>ss</sub>) of toripalimab-tpzi was 3.7 L (27%).
                  </p>
                  <p className="underline">Elimination</p>
                  <p>
                    The mean clearance (CL) was 14.9 mL/h (31%) after the first
                    dose and 9.5 mL/h (36%) at steady state. The mean terminal
                    half-life (t<sub>1/2</sub>) (± standard deviation) was 10 ±
                    1.5 days after the first dose and 18 ± 9.4 days at steady
                    state.
                  </p>
                  <p className="italic">Metabolism</p>
                  <p>
                    Toripalimab-tpzi is expected to be metabolized into small
                    peptides by catabolic pathways.{" "}
                  </p>
                  <p className="underline">Specific Populations</p>
                  <p>
                    No clinically significant differences in the
                    pharmacokinetics were observed based on age (21 to 85
                    years), body weight (32 to 164 kg), sex, race (White and
                    Asian), concomitant chemotherapy, mild renal impairment
                    (creatinine clearance [CLcr] 60 to 89 mL/min), mild hepatic
                    impairment (total bilirubin &gt; 1 to 1.5 times ULN with any
                    AST or total bilirubin ≤ ULN with AST &gt; ULN), tumor
                    burden and primary cancer.
                  </p>
                  <p>
                    The effect of moderate (total bilirubin &gt;1.5 to 3 times
                    ULN and any AST) or severe (total bilirubin &gt; 3 times ULN
                    and any AST) hepatic impairment or of moderate (CLcr 30 to
                    59 mL/min) or severe (CLcr 15 to 29 mL/min) renal impairment
                    on the pharmacokinetics of toripalimab-tpzi has not been
                    studied.
                  </p>
                </div>

                <div id="12.6" className="mt-[2rem]">
                  <p className={styles.subHeader}>12.6 Immunogenicity</p>
                  <p>
                    The observed incidence of anti-drug antibodies (ADA) is
                    highly dependent on the sensitivity and specificity of the
                    assay. Differences in assay methods preclude meaningful
                    comparisons of the incidence of ADA in the studies described
                    below with the incidence of ADA in other studies, including
                    those of LOQTORZI or of other toripalimab products.
                  </p>
                  <p>
                    Of the 146 evaluable patients in JUPITER-02 with
                    nasopharyngeal cancer who received LOQTORZI 240 mg every 3
                    weeks for a median duration of 15.1 months, in combination
                    with gemcitabine and cisplatin, 3.4% tested positive for
                    treatment-emergent ADA. Of the 190 evaluable patients in
                    study POLARIS-02 with nasopharyngeal cancer who received
                    LOQTORZI 3 mg/kg every 2 weeks for a median duration of 3.3
                    months, 3.7% of patients developed treatment-emergent ADA.
                    Neutralizing antibodies have not been tested.
                  </p>
                  <p>
                    Due to the low incidence of ADA, the effect of these
                    antibodies on the pharmacokinetics, pharmacodynamics,
                    safety, or effectiveness of LOQTORZI is unknown.
                  </p>
                </div>
              </div>
            ),
          },
          {
            id: "13",
            title: <span id="13">13 NONCLINICAL TOXICOLOGY</span>,
            content: (
              <div>
                <div id="13.1" className="mt-[2rem]">
                  <p className={styles.subHeader}>
                    13.1 Carcinogenesis, Mutagenesis, Impairment of Fertility
                  </p>
                  <p>
                    No studies have been performed to test the potential of
                    toripalimab-tpzi for carcinogenicity or genotoxicity.
                  </p>
                  <p>
                    Fertility studies have not been conducted with
                    toripalimab-tpzi. In 4-week and 26-week repeat-dose
                    toxicology studies in sexually mature cynomolgus monkeys,
                    there were no adverse or notable effects in the male and
                    female reproductive organs.
                  </p>
                </div>

                <div id="13.2" className="mt-[2rem]">
                  <p className={styles.subHeader}>
                    13.2 Animal Toxicology and/or Pharmacology
                  </p>
                  <p>
                    In animal models, inhibition of PD-1/PD-L1 signaling
                    increased the severity of some infections and enhanced
                    inflammatory responses. <em>Mycobacterium tuberculosis</em>
                    -infected PD-1 knockout mice exhibit markedly decreased
                    survival compared with wild-type controls, which correlated
                    with increased bacterial proliferation and inflammatory
                    responses in these animals. PD-1 blockade using a primate
                    anti-PD1 antibody was also shown to exacerbate{" "}
                    <em>M. tuberculosis</em> infection in rhesus macaques. PD-1
                    and PD-L1 knockout mice and mice receiving PD-L1 blocking
                    antibody have also shown decreased survival following
                    infection with lymphocytic choriomeningitis virus.{" "}
                  </p>
                </div>
              </div>
            ),
          },
          {
            id: "14",
            title: <span id="14">14 CLINICAL STUDIES</span>,
            content: (
              <div>
                <div id="14.1" className="mt-[2rem]">
                  <p className={styles.subHeader}>
                    14.1 First-line Treatment of Metastatic or Recurrent,
                    Locally Advanced NPC with Cisplatin and Gemcitabine
                  </p>
                  <p>
                    The efficacy of LOQTORZI in combination with cisplatin and
                    gemcitabine was investigated in JUPITER-02 (NCT03581786), a
                    randomized, multicenter, single region, double-blind,
                    placebo-controlled trial in 289 patients with metastatic or
                    recurrent, locally advanced NPC who had not received
                    previous systemic chemotherapy for recurrent or metastatic
                    disease. Patients with recurrent NPC after treatment with
                    curative intent were required to have an interval of at
                    least 6 months between the last dose of radiotherapy or
                    chemotherapy and recurrence. Patients with autoimmune
                    disease, other than stable hypothyroidism or Type I
                    diabetes, and patients who required systemic
                    immunosuppression were ineligible.{" "}
                  </p>
                  <p>
                    Randomization was stratified according to Eastern
                    Cooperative Oncology Group (ECOG) Performance Status (PS) (0
                    versus 1) and disease stage (recurrent versus metastatic).
                    Patients were randomized (1:1) to receive one of the
                    following treatments:
                  </p>
                  <ul className="bullet-list">
                    <li>
                      LOQTORZI 240 mg intravenously every 3 weeks in combination
                      with cisplatin 80 mg/m<sup>2</sup> on Day 1 every 3 weeks
                      gemcitabine 1000 mg/m<sup>2</sup> on Days 1 and 8 for up
                      to 6 cycles, followed by LOQTORZI 240 mg once every 3
                      weeks, or
                    </li>
                    <li>
                      Placebo intravenously every 3 weeks in combination with
                      cisplatin 80 mg/m<sup>2</sup> on Day 1 every 3 weeks and
                      gemcitabine 1000 mg/m<sup>2</sup> on Days 1 and 8 for up
                      to 6 cycles, followed by placebo once every 3 weeks.
                    </li>
                  </ul>
                  <p className="mt-[1em]">
                    Treatment with LOQTORZI or placebo continued until disease
                    progression per RECIST v1.1, unacceptable toxicity, or a
                    maximum of 2 years. Administration of LOQTORZI was permitted
                    beyond radiographic progression if the patient was deriving
                    benefit as assessed by the investigator. Tumor assessments
                    were performed every 6 weeks for the first 12 months and
                    every 9 weeks thereafter. The main efficacy outcome measure
                    was Blinded Independent Review Committee (BIRC)-assessed
                    progression-free survival (PFS) according to RECIST v1.1.
                    Additional efficacy outcome measures include BIRC-assessed
                    overall response rate (ORR) and overall survival (OS).{" "}
                  </p>
                  <p>
                    The study population characteristics were: median age of 48
                    years (range: 19 to 72), 4.8% age 65 or older, 83% male,
                    100% Asian, and 57% had ECOG PS of 0. Eighty-six percent of
                    patients had metastatic disease at study entry. Histological
                    subtypes of NPC included 98% non-keratinizing, 1%
                    keratinizing squamous cell carcinoma, and 1% did not have
                    the subtype identified.{" "}
                  </p>
                  <p>
                    Efficacy results of the pre-specified interim analysis of
                    PFS and final analysis of OS are summarized in{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("14")
                      }}
                      hash="table-7"
                      text="Table 7"
                    />{" "}
                    and{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("14")
                      }}
                      hash="figure-1"
                      text="Figure 1"
                    />{" "}
                    below. The trial demonstrated statistically significant
                    improvements in BIRC-assessed PFS, ORR, and OS for patients
                    randomized to LOQTORZI in combination with
                    cisplatin/gemcitabine compared to cisplatin and gemcitabine
                    with placebo.
                  </p>
                  <p id="table-7" className="font-MontserratSemiBold">
                    Table 7: Efficacy Results in JUPITER-02
                  </p>
                  <img className="w-full h-auto" src={PiTable7} alt="Table 7" />

                  <Footnotes
                    className="mt-[1em] !text-[1.2rem]"
                    items={[
                      {
                        bullet: <sup>1</sup>,
                        text: (
                          <span>
                            PFS and ORR results are based on the pre-specified
                            interim analysis with data cutoff of May 30, 2020.
                          </span>
                        ),
                      },
                      {
                        bullet: <sup>2</sup>,
                        text: (
                          <span>
                            OS results are based on the final analysis with a
                            data cutoff of November 18, 2022.
                          </span>
                        ),
                      },
                      {
                        bullet: <sup>3</sup>,
                        text: (
                          <span>
                            Based on the stratified Cox proportional-hazards
                            model using the stratification factors at
                            randomization, ECOG performance status and disease
                            stage.
                          </span>
                        ),
                      },
                      {
                        bullet: <sup>4</sup>,
                        text: (
                          <span>
                            Two-sided p-value, based on the stratified log-rank
                            test, as compared with an alpha boundary of 0.010.
                          </span>
                        ),
                      },
                      {
                        bullet: <sup>5</sup>,
                        text: (
                          <span>
                            Two-sided p-value, based upon
                            Cochran-Mantel-Haenszel test.
                          </span>
                        ),
                      },
                      {
                        bullet: <sup>6</sup>,
                        text: (
                          <span>
                            Two-sided p-value, based on the stratified log-rank
                            test, as compared with an alpha boundary of
                            0.049995.
                          </span>
                        ),
                      },
                      {
                        bullet: "",
                        text: (
                          <span>
                            BIRC=blinded independent review committee; CI=
                            confidence interval; NR=Not Reached; NE=Not
                            estimable.
                          </span>
                        ),
                      },
                    ]}
                  />

                  <div
                    id="figure-1"
                    className="mt-[1em] font-MontserratSemiBold"
                  >
                    Figure 1:{" "}
                    <div className="inline-block pl-[10.5rem]">
                      Kaplan-Meier Curves of Overall Survival for JUPITER-02
                    </div>
                  </div>
                  <img
                    className="w-full h-auto mt-[1em]"
                    src={PiFigure1}
                    alt="Figure 1"
                  />
                </div>

                <div id="14.2" className="mt-[2rem]">
                  <p className={styles.subHeader}>
                    14.2 Previously Treated Unresectable or Metastatic NPC
                  </p>
                  <p>
                    The efficacy of LOQTORZI was investigated in POLARIS-02 (NCT
                    02915432), an open-label, multicenter, multicohort trial
                    conducted in a single country. The trial included a total of
                    172 patients with unresectable or metastatic NPC who had
                    received prior platinum-based chemotherapy for treatment of
                    recurrent or metastatic NPC or had disease progression
                    within 6 months of completion of platinum-based chemotherapy
                    administered as neoadjuvant, adjuvant, or definitive
                    chemoradiation treatment for locally advanced disease. Key
                    exclusion criteria included previous treatment with an
                    anti-PD-(L)1 antibody; active autoimmune disease or other
                    medical conditions requiring immunosuppressive therapy.
                    Patients received LOQTORZI 3 mg/kg intravenously every 2
                    weeks until disease progression per RECIST v1.1 or
                    unacceptable toxicity. Tumor response assessments were
                    performed every 8 weeks for the first year and every 12
                    weeks thereafter. The major efficacy outcome measures were
                    confirmed ORR and duration of response (DOR) as assessed by
                    a Blinded Independent Review Committee (BIRC) using RECIST
                    v1.1.
                  </p>
                  <p>
                    The median age was 45 years (range: 22 to 68), 4.1% age 65
                    or older, 83% male, 100% Asian, and Eastern Cooperative
                    Oncology Group (ECOG) Performance Status (PS) of 0 (37%).
                    Patients had received a median of 2 prior systemic therapies
                    for recurrent/metastatic disease (range: 1-13). Ninety-five
                    percent of patients had metastatic disease, 95% had
                    non-keratinizing NPC, 2.9% had keratinizing squamous cell
                    carcinoma and 1.7% did not have the subtype identified.
                  </p>
                  <p>
                    Efficacy results for POLARIS-02 are summarized in{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("14")
                      }}
                      hash="table-8"
                      text="Table 8"
                    />{" "}
                    below.
                  </p>
                  <p id="table-8" className="font-MontserratSemiBold">
                    Table 8: Efficacy Results for POLARIS-02
                  </p>
                  <img className="w-full h-auto" src={PiTable8} alt="Table 8" />

                  <Footnotes
                    className={"mt-[1em] !text-[1.2rem]"}
                    items={[
                      {
                        bullet: "",
                        text: (
                          <span>
                            CI=confidence interval. n=number. NE = not
                            estimable.
                          </span>
                        ),
                      },
                      {
                        bullet: <sup>1</sup>,
                        text: (
                          <span>
                            Confirmed overall response rate assessed by BIRC
                          </span>
                        ),
                      },
                      {
                        bullet: <sup>2</sup>,
                        text: (
                          <span>Based on observed duration of response</span>
                        ),
                      },
                      {
                        bullet: "",
                        text: (
                          <span>BIRC=blinded independent review committee</span>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            ),
          },
          {
            id: "16",
            title: (
              <span id="16">
                16 HOW SUPPLIED/STORAGE <br className="tablet:hidden" />
                <span className="inline-block indent-[1em] tablet:indent-0">
                  AND HANDLING
                </span>
              </span>
            ),
            content: (
              <div>
                <p>
                  LOQTORZI (toripalimab-tpzi), injection is a clear to slightly
                  opalescent, colorless to slightly yellow solution supplied in
                  a carton containing one 240 mg/6 mL (40 mg/mL) single-dose
                  vial (NDC 70114-340-01).
                </p>
                <p>
                  Store vials refrigerated at 2°C to 8°C (36°F to 46°F) in
                  original carton to protect from light. Do not freeze. Do not
                  shake.
                </p>
              </div>
            ),
          },
          {
            id: "17",
            title: <span id="17">17 PATIENT COUNSELING INFORMATION</span>,
            content: (
              <div>
                <p>
                  Advise the patient to read the FDA-approved patient labeling
                  (Medication Guide).
                </p>
                <p className="underline">Immune-Mediated Adverse Reactions</p>
                <p>
                  Inform patients of the risk of immune-mediated adverse
                  reactions that may be severe or fatal, may occur after
                  discontinuation of treatment, and may require corticosteroid
                  treatment and interruption or discontinuation of LOQTORZI.
                  These reactions may include:
                </p>
                <ul className="bullet-list">
                  <li>
                    Pneumonitis: Advise patients to contact their healthcare
                    provider immediately for new or worsening cough, chest pain,
                    or shortness of breath{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("5")
                      }}
                      className="italic"
                      hash="5.1"
                      text="[see Warnings and Precautions (5.1)]"
                    />
                    .
                  </li>
                  <li>
                    Colitis: Advise patients to contact their healthcare
                    provider immediately for diarrhea or severe abdominal pain{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("5")
                      }}
                      className="italic"
                      hash="5.1"
                      text="[see Warnings and Precautions (5.1)]"
                    />
                    .
                  </li>
                  <li>
                    Hepatitis: Advise patients to contact their healthcare
                    provider immediately for jaundice, severe nausea or
                    vomiting, or easy bruising or bleeding{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("5")
                      }}
                      className="italic"
                      hash="5.1"
                      text="[see Warnings and Precautions (5.1)]"
                    />
                    .
                  </li>
                  <li>
                    Endocrinopathies: Advise patients to contact their
                    healthcare provider immediately for signs or symptoms of
                    adrenal insufficiency, hypophysitis, hypothyroidism,
                    hyperthyroidism, or Type 1 diabetes mellitus{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("5")
                      }}
                      className="italic"
                      hash="5.1"
                      text="[see Warnings and Precautions (5.1)]"
                    />
                    .
                  </li>
                  <li>
                    Nephritis: Advise patients to contact their healthcare
                    provider immediately for signs or symptoms of nephritis{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("5")
                      }}
                      hash="5.1"
                      text="[see Warnings and Precautions (5.1)]"
                    />
                    .
                  </li>
                  <li>
                    Severe skin reactions: Advise patients to contact their
                    healthcare provider immediately for any signs or symptoms of
                    severe skin reactions, SJS or TEN{" "}
                    <InternalHash
                      onClick={() => {
                        handleInternalHashClick("5")
                      }}
                      className="italic"
                      hash="5.1"
                      text="[see Warnings and Precautions (5.1)]"
                    />
                    .
                  </li>
                  <li>
                    Other immune-mediated adverse reactions:
                    <ul className="bullet-list-circle">
                      <li className="m-0">
                        Advise patients that other immune-mediated adverse
                        reactions can occur and may involve any organ system.
                        Advise patients to contact their healthcare provider
                        immediately for any new or worsening signs or symptoms{" "}
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("5")
                          }}
                          className="italic"
                          hash="5.1"
                          text="[see Warnings and Precautions (5.1)]"
                        />
                        .
                      </li>
                      <li>
                        Advise patients of the risk of solid organ transplant
                        rejection and to contact their healthcare provider
                        immediately for signs or symptoms of organ transplant
                        rejection{" "}
                        <InternalHash
                          onClick={() => {
                            handleInternalHashClick("5")
                          }}
                          className="italic"
                          hash="5.1"
                          text="[see Warnings and Precautions (5.1)]"
                        />
                        .
                      </li>
                    </ul>
                  </li>
                </ul>
                <p className="underline mt-[1em]">Infusion-Related Reactions</p>
                <p>
                  Advise patients to contact their healthcare provider
                  immediately for signs or symptoms of infusion-related
                  reactions{" "}
                  <InternalHash
                    onClick={() => {
                      handleInternalHashClick("5")
                    }}
                    className="italic"
                    hash="5.2"
                    text="[see Warnings and Precautions (5.2)]"
                  />
                  .
                </p>
                <p className="underline">Complications of Allogeneic HSCT</p>
                <p>
                  Advise patients of the risk of post-allogeneic hematopoietic
                  stem cell transplantation complications{" "}
                  <InternalHash
                    onClick={() => {
                      handleInternalHashClick("5")
                    }}
                    className="italic"
                    hash="5.3"
                    text="[see Warnings and Precautions (5.3)]"
                  />
                  .
                </p>
                <p className="underline">Embryo-Fetal Toxicity</p>
                <ul className="bullet-list">
                  <li>
                    Advise females of reproductive potential that LOQTORZI can
                    cause harm to a fetus and to inform their healthcare
                    provider of a known or suspected pregnancy{" "}
                    <span className="text-blue-200 italic">
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("5")
                        }}
                        className="italic"
                        hash="5.4"
                        text="[see Warnings and Precautions (5.4)"
                      />
                      ,{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("8")
                        }}
                        className="italic"
                        hash="8.1"
                        text="Use in Specific Populations"
                      />{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("8")
                        }}
                        hash="8.1"
                        text="(8.1"
                      />
                      ,{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("8")
                        }}
                        hash="8.3"
                        text="8.3)]"
                      />
                    </span>
                    .
                  </li>
                  <li>
                    Advise females of reproductive potential to use effective
                    contraception during treatment with LOQTORZI and for 4
                    months after the last dose{" "}
                    <span className="text-blue-200 italic">
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("5")
                        }}
                        className="italic"
                        hash="5.4"
                        text="[see Warnings and Precautions (5.4)"
                      />
                      ,{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("8")
                        }}
                        className="italic"
                        hash="8.1"
                        text="Use in Specific Populations"
                      />{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("8")
                        }}
                        hash="8.1"
                        text="(8.1"
                      />
                      ,{" "}
                      <InternalHash
                        onClick={() => {
                          handleInternalHashClick("8")
                        }}
                        hash="8.3"
                        text="8.3)]"
                      />
                    </span>
                    .
                  </li>
                </ul>
                <p className="underline mt-[1em]">Lactation</p>
                <p>
                  Advise women not to breastfeed during treatment with LOQTORZI
                  and for 4 months after the last dose{" "}
                  <InternalHash
                    onClick={() => {
                      handleInternalHashClick("8")
                    }}
                    className="italic"
                    hash="8.2"
                    text="[see Use in Specific Populations (8.2)]"
                  />
                  .
                </p>
                <p className="underline">Laboratory Tests</p>
                <p>
                  Advise patients of the importance of keeping scheduled
                  appointments for blood work or other laboratory tests{" "}
                  <InternalHash
                    onClick={() => {
                      handleInternalHashClick("5")
                    }}
                    className="italic"
                    hash="5.1"
                    text="[see Warnings and Precautions (5.1)]"
                  />
                  .
                </p>
                <p className="mt-[4rem]">
                  Manufactured and Distributed by:
                  <br />
                  <strong>Coherus BioSciences, Inc.</strong>
                  <br />
                  333 Twin Dolphin Drive, Suite 600
                  <br />
                  Redwood City, CA 94065, USA
                  <br />
                  U.S. License No. 2023
                  <br />
                  <br />
                  <Footnotes
                    className={"!text-[1.2rem]"}
                    items={[
                      {
                        bullet: "",
                        text: <span>PMD-0208, Rev. 01</span>,
                      },
                    ]}
                  />
                </p>
              </div>
            ),
          },
        ]}
      />
    </Layout>
  )
}
